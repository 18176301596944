import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';

import './Kennzahlen.scss';

import Container from 'react-bootstrap/Container';


const Kennzahlen = () => {

  const [startAnimation, setStartAnimation] = useState(false);
  const sectionRef = useRef(null);


  // start animation when section in viewport
  useEffect(() => {

    const detectIntersection = (entry) => {
      if (entry[0].isIntersecting) {
        setStartAnimation(true);
        observer.disconnect()
      }
    }

    const observer = new IntersectionObserver(detectIntersection, {
      threshold: 0,
    });

    observer.observe(sectionRef.current);
    return () => observer.disconnect();
  }, [sectionRef]);



  return (
    <Container ref={sectionRef} className="section-kennzahlen">
      <h2>Kennzahlen</h2>

      <div className="kennzahlen-element-container d-flex flex-wrap justify-content-between justify-content-md-start">
        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-green d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 10593 : 0} separator={'.'} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">Mitarbeitende</div>
            </div>
          </div>
        </div>


        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-blue d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-growth" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 5.4 : 0} separator={'.'} decimals={1} decimal={','} prefix="+" suffix=" %" />
              </div>
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 736.6 : 0} separator={'.'} decimals={1} decimal={','} />
              </div>
              <div className="kennzahl-description">Mio. Euro Konzerngesamtleistung</div>
            </div>
          </div>
        </div>

        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-green d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 28800 : 0} separator={'.'} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">Fachleistungsstunden in der Jugendhilfe</div>
            </div>
          </div>
        </div>


        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-blue d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 31.7 : 0} separator={'.'} decimals={1} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">Mio. Euro Investitionen</div>
            </div>
          </div>
        </div>

        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-green d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 1500 : 0} separator={'.'} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">Plätze Ganztagsbetreuung/&shy;Hort</div>
            </div>
          </div>
        </div>


        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-blue d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 638100 : 0} separator={'.'} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">Pflegetage/&shy;Betreuungstage</div>
            </div>
          </div>
        </div>

        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-green d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 80100 : 0} separator={'.'} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">Casemix-Punkte</div>
            </div>
          </div>
        </div>


        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-blue d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 86100 : 0} separator={'.'} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">ambulante Fälle in Medizinischen Versorgungszentren</div>
            </div>
          </div>
        </div>

        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-green d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 162300 : 0} separator={'.'} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">ambulante Fälle in Krankenhäusern</div>
            </div>
          </div>
        </div>


        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-blue d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 61100 : 0} separator={'.'} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">ambulante Fälle im Sport- und Rehabereich</div>
            </div>
          </div>
        </div>

        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-green d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 600 : 0} separator={'.'} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">Plätze Proclusio</div>
            </div>
          </div>
        </div>


        <div className="kennzahlen-element">
          <div className="kennzahl kennzahl-blue d-flex justify-content-center align-items-center">
            <div className="kennzahl-content">
              <div className="kennzahl-number" style={!startAnimation ? { opacity: 0 } : { opacity: 1 }}>
                <CountUp scrollSpyDelay={0} duration={4} end={startAnimation ? 85700 : 0} separator={'.'} decimal={','} enableScrollSpy={true} scrollSpyOnce={true} />
              </div>
              <div className="kennzahl-description">stationäre Patient*innen</div>
            </div>
          </div>
        </div>
      </div>

    </Container >
  );
}

export default Kennzahlen;
