import React from 'react';
import './Karte.scss';
import Container from 'react-bootstrap/Container';

const Karte = () => (
  <Container className="karte-container" fluid>
    <iframe
      src="https://www.johannesstift-diakonie.de/einrichtungsfinder/"
      title="Einrichtungsfinder"
      loading="lazy"
      className="iframe" />
  </Container >
);

export default Karte;
