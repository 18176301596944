import React, { useState, useEffect } from 'react';


import './custom.scss';
import './App.scss';

// fonts
import "./fonts/5631499/f1393402-017c-45b3-befc-78eeba33562e.eot";
import "./fonts/5631499/ad734153-699d-48a8-85d1-5ed1ad410b69.woff2";
import "./fonts/5631499/12ea4496-da88-4292-89e0-58f2fc9da2bf.woff";
import "./fonts/5631499/6ec74d39-8d1a-4239-ab93-96e943cec785.ttf";
import "./fonts/5631529/411a3fac-3843-4789-85de-1b71781e1aa3.eot";
import "./fonts/5631529/5014e154-3299-48c9-a82c-479ff9a6bb92.woff2";
import "./fonts/5631529/7f78a096-76ae-4cce-9f16-dbac3035417e.woff";


// components
import Navbar from './components/NavBar/NavBar';
import Hero from './components/Hero/Hero';
import Vorstand from './components/Vorstand/Vorstand';
import Netzwerk from './components/Netzwerk/Netzwerk';
import Chronik from './components/Chronik/Chronik';
import Kennzahlen from './components/Kennzahlen/Kennzahlen';
import Karte from './components/Karte/Karte';
import Mitarbeiter from './components/Mitarbeiter/Mitarbeiter';
import Download from './components/Download/Download';
import Footer from './components/Footer/Footer';

import throttle from 'lodash/throttle';



const App = () => {
  const [viewport, setViewport] = useState(window.innerWidth);

  // viewport event listener
  useEffect(() => {
    document.documentElement.lang = 'de';

    const handleWindowResizeThrottled = throttle(handleWindowResize, 250, { trailing: true, leading: true });

    window.addEventListener("resize", handleWindowResizeThrottled);
    return () => window.removeEventListener("resize", handleWindowResizeThrottled);
  }, []);



  // on window resize
  const handleWindowResize = () => {
    // set viewport
    setViewport(window.innerWidth)
    // set --vh css variable (excludes browser address bar height for mobile browsers)
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  const handleBackToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Navbar viewport={viewport} />

      <section id="hero" className="hero-section d-lg-flex flex-column justify-center">
        <Hero viewport={viewport} />
      </section>

      <section id="vorstand">
        <Vorstand />
      </section>

      <section id="netzwerk">
        <Netzwerk viewport={viewport} />
      </section>


      <section id="chronik" style={(viewport < 1024) ? {} : { overflow: "hidden" }}>
        <Chronik viewport={viewport} />
      </section>


      <section id="kennzahlen">
        <Kennzahlen />
      </section>

      <section id="mitarbeiter">
        <Mitarbeiter />
      </section>

      <div id="karte-scrollspy" />
      <section id="karte">
        <Karte />
      </section>

      <section id="download">
        <Download />
      </section>

      <Footer />

      <div className="position-sticky btt-container container">
        <div className="back-to-top" onClick={handleBackToTop} />
      </div>

    </>
  );
}
export default App;