import React, { useState, Suspense, lazy } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Ratio from 'react-bootstrap/Ratio';
import Spinner from 'react-bootstrap/Spinner';

import './Mitarbeiter.scss';

const MitarbeiterBerlin = lazy(() => import('./MitarbeiterBerlin.js'))
const MitarbeiterDeutschland = lazy(() => import('./MitarbeiterDeutschland.js'))

const Mitarbeiter = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Container className="mitarbeiter-container">
      {/* <img className="w-100" src="images/mitarbeiter-deutschland.png" alt="" /> */}
      <Row>
        <Col lg={10} className="mx-auto px-0">
          <Carousel activeIndex={index} onSelect={handleSelect} variant="dark" indicators={false}>
            <Carousel.Item>
              <Suspense fallback={
                <Ratio aspectRatio={456.38 / 800}>
                  <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner></div>
                </Ratio>}>
                <Ratio aspectRatio={456.38 / 800}>
                  <MitarbeiterDeutschland title="Mitarbeitende der Johannesstift Diakonie in Deutschland nach Sparten" alt="Grafik: Mitarbeitende der Johannesstift Diakonie in Deutschland nach Sparten" />
                </Ratio>
              </Suspense>
            </Carousel.Item>
            <Carousel.Item>
              <Suspense fallback={
                <Ratio aspectRatio={456.38 / 800}>
                  <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner></div>
                </Ratio>}>
                <Ratio aspectRatio={456.38 / 800}>
                  <MitarbeiterBerlin title="Mitarbeitende der Johannesstift Diakonie in Berlin nach Sparten" alt="Grafik: Mitarbeitende der Johannesstift Diakonie in Berlin nach Sparten" />
                </Ratio>

              </Suspense>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  )
}

export default Mitarbeiter;