import React, { useState, useRef, Suspense, lazy } from 'react';

import './Netzwerk.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Ratio from 'react-bootstrap/Ratio';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';


import { animateScroll as scroll, scroller } from "react-scroll";

const NetzwerkDetail = lazy(() => import('../NetzwerkDetail/NetzwerkDetail'));
const NetzwerkAnimation = lazy(() => import('./NetzwerkAnimation'));

const Netzwerk = (props) => {
  const [categoryHover, setCategoryHover] = useState(false);
  const [categoryActive, setCategoryActive] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const kundenRef = { lottieRef: useRef(null), containerRef: useRef(null) };
  const identitaetRef = { lottieRef: useRef(null), containerRef: useRef(null) };
  const nachhaltigkeitRef = { lottieRef: useRef(null), containerRef: useRef(null) };
  const prozesseRef = { lottieRef: useRef(null), containerRef: useRef(null) };
  const arbeitgeberRef = { lottieRef: useRef(null), containerRef: useRef(null) };

  const handleMouseEnter = (category) => {
    if (!categoryHover) {
      setCategoryHover(category);
    }
  }

  const handleMouseLeave = () => {
    if (categoryHover) {
      setCategoryHover(false);
    }
  }

  const handleClickCategory = (category) => {
    scroller.scrollTo('netzwerk', { duration: 5000, delay: 5000 });
    setCategoryActive(category);
    setIsZoomed(true);
  }

  const handleClose = () => {
    setCategoryActive(false);
    setIsZoomed(false);
  }


  // zoom animation positions for desktop
  const zoomPosDesktop = {
    kunden: { transform: 'scale(2.5) translate(5%, 32%)' },
    nachhaltigkeit: { transform: 'scale(2.5) translate(8%, -32.5%)' },
    identitaet: { transform: 'scale(3.5) translate(-16%, 13%)' },
    prozesse: { transform: 'scale(2.5) translate(-58%, 20%)' },
    arbeitgeber: { transform: 'scale(2.5) translate(-34%, -30%)' }
  };

  // zoom animation positions for desktop
  const zoomPosMobile = {
    kunden: { transform: 'scale(2.5) translate(23%, 35%)' },
    nachhaltigkeit: { transform: 'scale(2.5) translate(30%, -33%)' },
    identitaet: { transform: 'scale(3.5) translate(-2%, 5%)' },
    prozesse: { transform: 'scale(2.5) translate(-34%, 16%)' },
    arbeitgeber: { transform: 'scale(2.5) translate(-24%, -32%)' }
  };

  return (
    <Container className="netzwerk-section-container">

      {/* Netzwerk detail view */}

      {!isZoomed ? "" :

        <Suspense fallback={
          <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner></div>}>
          <NetzwerkDetail handleClose={handleClose} handleClickCategory={handleClickCategory} categoryActive={categoryActive} isNetzwerkOverlay={true} viewport={props.viewport} className="mw-100" />
        </Suspense>
      }

      <Row>
        <Col style={{ zIndex: 3 }}>
          <h2>Netzwerk</h2>
          <span className="d-flex align-items-center" style={isZoomed ? { opacity: 0, transition: "0.5s opacity" } : { opacity: 1, transition: "1s opacity", transitionDelay: "1.5s" }}>
            <div className="touch-icon" /><span className="touch-prompt text-lg-regular">Netzwerk jetzt erkunden</span></span>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col lg="8">
          <div className="netzwerk-container" style={(props.viewport < 1024) ? zoomPosMobile[categoryActive] : zoomPosDesktop[categoryActive]}>
            {/* Grafik background */}
            {(props.viewport < 1024) ?
              <img src="animations/netzwerkgrafik-mobile.png" className="w-100" alt="network with connected circles" />
              :
              <img src="animations/netzwerkgrafik-verbindungslinien-desktop.png" className="w-100" alt="network with connected circles" />}
            {/* Identität - animation */}
            <Ratio aspectRatio="1x1" className="netzwerk-icon netzwerk-icon-identitaet"
              onMouseEnter={() => handleMouseEnter("identitaet")}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClickCategory("identitaet")} >

              {(props.viewport < 1024) ?
                // <IconIdentitaet className="netzwerk-mobile-svg" /> :
                <div className="netzwerk-mobile-svg" /> :

                <Suspense fallback={
                  <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner></div>}>
                  <NetzwerkAnimation
                    animationData={"../../animations/animation-icon-identitaet.json"}
                    categoryHover={categoryHover}
                    initialFrame={45}
                    index={0}
                    ref={identitaetRef}
                    name="identitaet"
                  /></Suspense>}

            </Ratio>

            {/* Kunden - animation */}
            <Ratio aspectRatio="1x1" className="netzwerk-icon netzwerk-icon-kunden"
              onMouseEnter={() => handleMouseEnter("kunden")}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClickCategory("kunden")}>

              {(props.viewport < 1024) ?
                // <IconKunden className="netzwerk-mobile-svg" /> :
                <div className="netzwerk-mobile-svg" /> :

                <Suspense fallback={
                  <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner></div>}>
                  <NetzwerkAnimation
                    animationData={"../../animations/animation-icon-kunden.json"}
                    categoryHover={categoryHover}
                    initialFrame={30}
                    ref={kundenRef}
                    name="kunden"
                  />
                </Suspense>}
            </Ratio>

            {/* Nachhaltigkeit - animation */}
            <Ratio aspectRatio="1x1" className="netzwerk-icon netzwerk-icon-nachhaltigkeit"
              onMouseEnter={() => handleMouseEnter("nachhaltigkeit")}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClickCategory("nachhaltigkeit")}>

              {(props.viewport < 1024) ?
                // <IconNachhaltigkeit className="netzwerk-mobile-svg" /> :
                <div className="netzwerk-mobile-svg" /> :

                <Suspense fallback={
                  <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner></div>}>
                  <NetzwerkAnimation
                    animationData={"../../animations/animation-icon-nachhaltigkeit.json"}
                    categoryHover={categoryHover}
                    initialFrame={45}
                    ref={nachhaltigkeitRef}
                    name="nachhaltigkeit"
                  />
                </Suspense>}
            </Ratio>

            {/* Prozesse - animation */}
            <Ratio aspectRatio="1x1" className="netzwerk-icon netzwerk-icon-prozesse"
              onMouseEnter={() => handleMouseEnter("prozesse")}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClickCategory("prozesse")} >

              {(props.viewport < 1024) ?
                // <IconProzesse className="netzwerk-mobile-svg" /> :
                <div className="netzwerk-mobile-svg" /> :

                <Suspense fallback={
                  <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner></div>}>
                  <NetzwerkAnimation
                    animationData={"../../animations/animation-icon-prozesse.json"}
                    categoryHover={categoryHover}
                    initialFrame={30}
                    ref={prozesseRef}
                    name="prozesse"
                  />
                </Suspense>}
            </Ratio>

            {/* Arbeitgebermarke - animation */}
            <Ratio aspectRatio="1x1" className="netzwerk-icon netzwerk-icon-arbeitgeber"
              onMouseEnter={() => handleMouseEnter("arbeitgeber")}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClickCategory("arbeitgeber")}>

              {(props.viewport < 1024) ?
                // <IconArbeitgeber className="netzwerk-mobile-svg" /> :
                <div className="netzwerk-mobile-svg" /> :

                <Suspense fallback={
                  <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner></div>}>
                  <NetzwerkAnimation
                    animationData={"../../animations/animation-icon-arbeitgeber.json"}
                    categoryHover={categoryHover}
                    initialFrame={30}
                    ref={arbeitgeberRef}
                    name="arbeitgeber"
                  />
                </Suspense>}
            </Ratio>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={isZoomed ? "d-flex flex-wrap col-lg-5" : "d-flex flex-wrap col-lg-12 justify-content-xl-center"}>
          <Button className={`button button-legende ${categoryHover === "kunden" ? "button-hover" : ""} ${categoryActive === "kunden" ? "button-active" : ""}`}
            onClick={() => handleClickCategory("kunden")}><span className="icon-netzwerk icon-netzwerk-kunden" /><span className="button-text-center">Kund*innenzufriedenheit</span></Button>
          <Button className={`button button-legende ${categoryHover === "nachhaltigkeit" ? "button-hover" : ""} ${categoryActive === "nachhaltigkeit" ? "button-active" : ""}`}
            onClick={() => handleClickCategory("nachhaltigkeit")}><span className="icon-netzwerk icon-netzwerk-nachhaltigkeit" /><span className="button-text-center">Nachhaltigkeit</span></Button>
          <Button className={`button button-legende ${categoryHover === "prozesse" ? "button-hover" : ""} ${categoryActive === "prozesse" ? "button-active" : ""}`}
            onClick={() => handleClickCategory("prozesse")}><span className="icon-netzwerk icon-netzwerk-prozesse" /><span className="button-text-center">Prozesse</span></Button>
          <Button className={`button button-legende ${categoryHover === "identitaet" ? "button-hover" : ""} ${categoryActive === "identitaet" ? "button-active" : ""}`}
            onClick={() => handleClickCategory("identitaet")}><span className="icon-netzwerk icon-netzwerk-identitaet" /><span className="button-text-center">Identität&nbsp;&&nbsp;Kultur</span></Button>
          <Button className={`button button-legende ${categoryHover === "arbeitgeber" ? "button-hover" : ""} ${categoryActive === "arbeitgeber" ? "button-active" : ""}`}
            onClick={() => handleClickCategory("arbeitgeber")}><span className="icon-netzwerk icon-netzwerk-arbeitgeber" /><span className="button-text-center">Stärkung&nbsp;Arbeitgebermarke</span></Button>
        </Col>
      </Row>
    </Container >
  )
};


export default Netzwerk;