import React from 'react';
import './Download.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


const Download = () => (
  <Container className="download-container">
    {/*  d-flex flex-column justify-content-center align-items-center text-center */}
    <Row>
      <Col className="offset-xl-1 mb-4 mb-md-0" xs={12} md={5}>
        <h3>Für Sie zum Download.</h3>
      </Col>
      <Col xs={12} md={3} className="offset-md-1">
        <Button
          className="button button-close button-gb d-flex justify-content-between w-100" title="Gesamtdokument herunterladen (PDF)"
          href="pdfs/JSDgAG_GB22_Gesamtdokument.pdf" target="_blank">
          <span>Gesamtdokument</span><span className="icon-netzwerk icon-download" />
        </Button>
        <Button
          className="button button-close button-gb d-flex justify-content-between w-100" title="Lagebericht/Jahresbilanz herunterladen (PDF)"
          href="pdfs/JSDgAG_GB22_Konzernanhang.pdf" target="_blank">
          <span>Lagebericht/Jahresbilanz</span><span className="icon-netzwerk icon-download" />
        </Button>
        <Button
          className="button button-close button-gb d-flex justify-content-between w-100" title="Karte herunterladen (PDF)"
          href="pdfs/JSDgAG_GB22_Konzernanhang_Karte_MA.pdf" target="_blank">
          <span>Einrichtungskarte</span><span className="icon-netzwerk icon-download" />
        </Button>
        <Button
          className="button button-close button-gb d-flex justify-content-between w-100" title="Factsheet herunterladen (PDF)"
          href="pdfs/JSDgAG_GB22_Factsheet.pdf" target="_blank">
          <span>Factsheet</span><span className="icon-netzwerk icon-download" />
        </Button>
      </Col>
    </Row>
  </Container>
);

export default Download;
