import React, { useEffect, useRef } from 'react';
import './Hero.scss';

import Ratio from 'react-bootstrap/Ratio';
import lottie from 'lottie-web/build/player/lottie_canvas.min.js';


const HeroAnimation = (props) => {
  const containerRef = useRef();
  const lottieRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      lottieRef.current?.destroy()
      lottieRef.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'canvas',
        loop: true,
        path: props.animationData,
        rendererSettings: {
          progressiveLoad: true,
          runExpressions: false
        }
      })
    }
    lottieRef.current.playSegments(
      [
        [0, 83],
        [84, 449],
      ],
      true,
    )
    return () => {
      lottieRef.current?.destroy()
      lottieRef.current = null
    }
  }, [props.animationData])



  // pause animation when out of viewport
  useEffect(() => {

    const detectIntersection = (entry) => {
      if (lottieRef.current && entry[0].isIntersecting) {
        lottieRef.current.play();
      } else {
        lottieRef.current.pause();
      }
    }

    const observer = new IntersectionObserver(detectIntersection, {
      threshold: 0,
    });

    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [containerRef]);



  return (
    <>
      <Ratio aspectRatio="1x1">
        <div className="w-100 h-100" ref={containerRef} />
      </Ratio>
    </>
  )
};

export default HeroAnimation;