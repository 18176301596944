import React, { useState, useEffect, Suspense, lazy } from 'react';
import './Hero.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import HeroAnimation from './HeroAnimation';


const Hero = (props) => {
  const [animationData, setAnimationData] = useState("../../animations/animation-header-mobile.json");

  useEffect(() => {
    // swap to Desktop animation
    if (!(props.viewport < 1024)) {
      setAnimationData("../../animations/animation-header-desktop.json")
    } else {
      setAnimationData("../../animations/animation-header-mobile.json")
    }

  }, [(props.viewport)]);



  return (
    <Container className="hero-container d-flex flex-column justify-content-center">
      <Row>
        <Col xs={12} md={6} className="my-auto order-2 order-md-1">
          <div className="d-flex justify-content-center justify-content-md-start overflow-hidden">
            <h1 className="hero-title d-flex d-md-block flex-column justify-content-center align-items-center">
              <div className="title-text">Geschäftsbericht<br /></div>
              <div className="title-number">2022<br /></div>
              <div className="title-text title-claim">Ein sicheres Netz</div></h1>
          </div>
        </Col>
        <Col xs={12} md={6} className="order-1 order-md-2 h-100">
          {/* <Suspense fallback={
            <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner></div>}> */}
          <HeroAnimation animationData={animationData} />
          {/* </Suspense> */}
        </Col>
      </Row>
    </Container>
  )
};

export default Hero;
