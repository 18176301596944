import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import '../NetzwerkDetail/NetzwerkDetail.scss';
import './Chronik.scss';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { Link, animateScroll as scroll, scroller } from "react-scroll";

import content from '../../content.json';

import { ReactComponent as IconArrow } from '../../icons/icon-pfeil-rechts.svg';

const NetzwerkDetail = lazy(() => import('../NetzwerkDetail/NetzwerkDetail'));

// add index to chronik content entries
for (let entry in content.chronik) {
  content.chronik[entry].index = entry;
}

const Chronik = (props) => {
  const [quartal, setQuartal] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionIndex, setTransitionIndex] = useState(0);
  const [categoryActive, setCategoryActive] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [showStoriesQ1, setShowStoriesQ1] = useState((props.viewport < 1024) ? 4 : 20);
  const [showStoriesQ2, setShowStoriesQ2] = useState((props.viewport < 1024) ? 4 : 20);
  const [showStoriesQ3, setShowStoriesQ3] = useState((props.viewport < 1024) ? 4 : 20);
  const [showStoriesQ4, setShowStoriesQ4] = useState((props.viewport < 1024) ? 4 : 20);
  const entryRefs = useRef([]);
  const quartalRefs = useRef([]);


  // intersection observer: change image when paragraph next to image container
  useEffect(() => {
    setShowStoriesQ1((props.viewport < 1024) ? 4 : 20);
    setShowStoriesQ2((props.viewport < 1024) ? 4 : 20);
    setShowStoriesQ3((props.viewport < 1024) ? 4 : 20);
    setShowStoriesQ4((props.viewport < 1024) ? 4 : 20);

    if (!(props.viewport < 1024)) {
      // callback for intersection observer
      const detectIntersection = (entries) => {
        if (!detailVisible) {
          for (let e of entries) {
            let i = entryRefs.current.indexOf(e.target);
            if (e.isIntersecting) {
              requestAnimationFrame(ts => {
                // set index (paragraph / image)
                setCurrentIndex(i);
                // start transition animation
                setTransitioning(true);
              })
            }
          }
        }
      }

      // intersection observer options
      const observer = new IntersectionObserver(
        detectIntersection, {
        root: document.querySelector("#intersectRoot"),
        rootMargin: "-200px 0px 0px 0px",
        threshold: 1
      }
      );

      // observe chronik paragraphs 
      entryRefs.current.forEach(element => {
        if (element) { observer.observe(element) }
      }, []);


      // cleanup 
      return () => {
        observer.disconnect();
      };

    }
  }, [props.viewport, entryRefs, detailVisible]);


  // intersection observer: change quartal when new quartal section visible
  useEffect(() => {

    // callback for intersection observer
    const detectIntersection = (entries) => {
      for (let e of entries) {
        let i = quartalRefs.current.indexOf(e.target);
        if (e.isIntersecting) {
          requestAnimationFrame(ts => {
            setQuartal(i + 1);
          })
        }
      }
    }

    // intersection observer options
    const observer = new IntersectionObserver(
      detectIntersection, {
      root: document.querySelector("#scroll-container"),
      rootMargin: '-50% 0% -50% 0%',
      threshold: 0
    });

    // observe quartal headlines 
    quartalRefs.current.forEach(element =>
      observer.observe(element));

    // cleanup 
    return () => {
      observer.disconnect();
    };
  }, [])


  // fired when crossfade opacity animation ends 
  const handleCrossfadeEnd = () => {
    setTransitioning(false);
    setTransitionIndex(currentIndex)
  }

  const handleClickCategory = (category) => {
    setCategoryActive(category);
    setDetailVisible(true);

    const offset = props.viewport < 1024 ? 60 : 0;
    scroller.scrollTo('chronik', { offset: offset });


    const html = document.querySelector('html');
    if (html) {
      html.style.overflowY = 'hidden';
    }

  }

  const handleClose = () => {
    setCategoryActive(false);
    setDetailVisible(false);

    const html = document.querySelector('html');
    if (html) {
      html.style.overflowY = 'auto';
    }
  }


  return (
    <>
      {/* Modal blur effect (when opening netzwerk detail view) */}
      <div className={detailVisible ? "modal-blur-container modal-blur" : "modal-blur-container"}>

      </div>
      <Container className="chronik-section-container">
        {!detailVisible ? "" :
          <Suspense fallback={
            <div className="d-flex justify-content-center align-items-center h-100 w-100 text-jsd-green"><Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner></div>}>
            <NetzwerkDetail viewport={props.viewport} handleClose={handleClose} handleClickCategory={handleClickCategory} categoryActive={categoryActive} style={{ zIndex: 100 }} isNetzwerkOverlay={false} />
          </Suspense>
        }

        <Row className={(props.viewport < 1024) ? "chronik-nav sticky-top" : "chronik-nav"} id="chronik-nav">
          <Col>
            <h2>Chronik</h2>
            <div className="d-flex d-lg-block justify-content-between justify-content-sm-start">
              <Link containerId="chronik-scroll-container" to="quartal1">
                <Button
                  className={(quartal === 1) ? "button button-active netzwerk-detail-button q-button" : "button netzwerk-detail-button q-button"}>
                  <span>Q1</span>
                </Button>
              </Link>
              <Link containerId="chronik-scroll-container" to="quartal2">
                <Button
                  className={(quartal === 2) ? "button button-active netzwerk-detail-button q-button" : "button netzwerk-detail-button q-button"}>
                  <span>Q2</span>
                </Button>
              </Link>
              <Link containerId="chronik-scroll-container" to="quartal3">
                <Button
                  className={(quartal === 3) ? "button button-active netzwerk-detail-button q-button" : "button netzwerk-detail-button q-button"}>
                  <span>Q3</span>
                </Button>
              </Link>
              <Link containerId="chronik-scroll-container" to="quartal4">
                <Button
                  className={(quartal === 4) ? "button button-active netzwerk-detail-button q-button" : "button netzwerk-detail-button q-button"}>
                  <span>Q4</span>
                </Button>
              </Link>
            </div>
            <div>
              <Link to="kennzahlen">
                <Button className="button button-close button-skip">
                  Chronik überspringen
                </Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Row id="#intersectRoot" className={(props.viewport < 1024) ? "chronik-scroll-container-mobile" : ""}>

          {(props.viewport < 1024) ? "" : <Col>
            <div>
              {/* image */}
              <div className="chronik-image-container">
                <img src={"chronik/" + content.chronik[currentIndex].image + "_576.jpg"} className="chronik-image" loading="lazy" title={content.chronik[currentIndex].imageTitle} alt={content.chronik[currentIndex].imageTitle} />
                {/* crossfade transition: */}
                <img src={"chronik/" + content.chronik[transitionIndex].image + "_576.jpg"} className={(transitioning) ? "chronik-image-transition-element chronik-image-transition" : "chronik-image-transition-element"}
                  title={content.chronik[currentIndex].imageTitle} alt={content.chronik[currentIndex].imageTitle} loading="lazy" onAnimationEnd={(e) => handleCrossfadeEnd()} />
              </div>
            </div>
            <div className="chronik-number text-night-blue">{currentIndex + 1}/{content.chronik.length}</div>
          </Col>}

          <Col style={(props.viewport < 1024) ? { marginTop: "0" } : { marginTop: -160, marginBottom: -135 }}>
            <div className=" chronik-scroll-container" >
              <div className="h-100 chronik-scrollbar-container" id="chronik-scroll-container" >
                <div id="section-quartal"
                  ref={(ref) => { quartalRefs.current[0] = ref }}>
                  <h3 className="chronik-quartal-headline" id="quartal1">Quartal 1</h3>
                  {/* chronik content */}
                  {content.chronik.filter(element => element.quartal === "1").slice(0, showStoriesQ1).map(element =>
                    <div
                      ref={(ref) => { entryRefs.current[element.index] = ref }}
                      key={content.chronik.indexOf(element)}
                      className="chronik-element">
                      {(props.viewport < 1024) ?
                        <picture>
                          <source media="(min-width: 576px)" srcSet={"chronik/" + element.image + "_1280.jpg"} />
                          <source media="(min-width: 384px)" srcSet={"chronik/" + element.image + "_576.jpg"} />
                          <source media="(min-width: 320px)" srcSet={"chronik/" + element.image + "_384.jpg"} />
                          <img src={props.viewport < 576 ? "chronik/" + element.image + "_384.jpg" : "chronik/" + element.image + "_1280.jpg"} className="chronik-image" loading="lazy" title={element.imageTitle} alt={element.imageTitle} />
                        </picture>
                        : ""
                      }
                      <h5>{element.headline}</h5>
                      <p>{element.content}</p>
                      {/* Category buttons => click functionality fehlt noch */}
                      {element.categories.map(category =>
                        <Button className={"button netzwerk-button-round"} key={content.chronik.indexOf(element) + "_" + element.categories.indexOf(category)} onClick={() => handleClickCategory(category)}>
                          <span className={`icon-netzwerk icon-netzwerk-round icon-netzwerk-${category}`} />
                        </Button>
                      )}
                    </div>
                  )}

                  {(showStoriesQ1 === 4) ? <Button className="button button-jsdgreen" onClick={() => setShowStoriesQ1(20)}><span>Mehr anzeigen</span><IconArrow style={{ marginLeft: 8 }} /></Button> : ""}

                </div>


                <div id="section-quartal"
                  ref={(ref) => { quartalRefs.current[1] = ref }}>
                  <h3 className="chronik-quartal-headline" id="quartal2">Quartal 2</h3>
                  {/* chronik content */}
                  {content.chronik.filter(element => element.quartal === "2").slice(0, showStoriesQ2).map(element =>
                    <div
                      ref={(ref) => { entryRefs.current[element.index] = ref }}
                      key={content.chronik.indexOf(element)}
                      className="chronik-element">
                      {(props.viewport < 1024) ?
                        <picture>
                          <source media="(min-width: 576px)" srcSet={"chronik/" + element.image + "_1280.jpg"} />
                          <source media="(min-width: 384px)" srcSet={"chronik/" + element.image + "_576.jpg"} />
                          <source media="(min-width: 320px)" srcSet={"chronik/" + element.image + "_384.jpg"} />
                          <img src={props.viewport < 576 ? "chronik/" + element.image + "_384.jpg" : "chronik/" + element.image + "_1280.jpg"} className="chronik-image" loading="lazy" title={element.imageTitle} alt={element.imageTitle} />
                        </picture>
                        : ""
                      }
                      <h5>{element.headline}</h5>
                      <p>{element.content}</p>
                      {/* Category buttons => click functionality fehlt noch */}
                      {element.categories.map(category =>
                        <Button className={"button netzwerk-button-round"} key={content.chronik.indexOf(element) + "_" + element.categories.indexOf(category)} onClick={() => handleClickCategory(category)}>
                          <span className={`icon-netzwerk icon-netzwerk-round icon-netzwerk-${category}`} />
                        </Button>
                      )}
                    </div>
                  )}
                  {(showStoriesQ2 === 4) ? <Button className="button button-jsdgreen" onClick={() => setShowStoriesQ2(20)}><span>Mehr anzeigen</span><IconArrow style={{ marginLeft: 8 }} /></Button> : ""}

                </div>

                <div id="section-quartal"
                  ref={(ref) => { quartalRefs.current[2] = ref }}>
                  <h3 className="chronik-quartal-headline" id="quartal3">Quartal 3</h3>
                  {/* chronik content */}
                  {content.chronik.filter(element => element.quartal === "3").slice(0, showStoriesQ3).map(element =>
                    <div
                      ref={(ref) => { entryRefs.current[element.index] = ref }}
                      key={content.chronik.indexOf(element)}
                      className="chronik-element">
                      {(props.viewport < 1024) ?
                        <picture>
                          <source media="(min-width: 576px)" srcSet={"chronik/" + element.image + "_1280.jpg"} />
                          <source media="(min-width: 384px)" srcSet={"chronik/" + element.image + "_576.jpg"} />
                          <source media="(min-width: 320px)" srcSet={"chronik/" + element.image + "_384.jpg"} />
                          <img src={props.viewport < 576 ? "chronik/" + element.image + "_384.jpg" : "chronik/" + element.image + "_1280.jpg"} className="chronik-image" loading="lazy" title={element.imageTitle} alt={element.imageTitle} />
                        </picture>
                        : ""
                      }
                      <h5>{element.headline}</h5>
                      <p>{element.content}</p>
                      {/* Category buttons => click functionality fehlt noch */}
                      {element.categories.map(category =>
                        <Button className={"button netzwerk-button-round"} key={content.chronik.indexOf(element) + "_" + element.categories.indexOf(category)} onClick={() => handleClickCategory(category)}>
                          <span className={`icon-netzwerk icon-netzwerk-round icon-netzwerk-${category}`} />
                        </Button>
                      )}
                    </div>
                  )}
                  {(showStoriesQ3 === 4) ? <Button className="button button-jsdgreen" onClick={() => setShowStoriesQ3(20)}><span>Mehr anzeigen</span><IconArrow style={{ marginLeft: 8 }} /></Button> : ""}

                </div>

                <div id="section-quartal"
                  ref={(ref) => { quartalRefs.current[3] = ref }}>
                  <h3 className="chronik-quartal-headline" id="quartal4">Quartal 4</h3>
                  {/* chronik content */}
                  {content.chronik.filter(element => element.quartal === "4").slice(0, showStoriesQ4).map(element =>
                    <div
                      ref={(ref) => { entryRefs.current[element.index] = ref }}
                      key={content.chronik.indexOf(element)}
                      className="chronik-element">
                      {(props.viewport < 1024) ?
                        <picture>
                          <source media="(min-width: 576px)" srcSet={"chronik/" + element.image + "_1280.jpg"} />
                          <source media="(min-width: 384px)" srcSet={"chronik/" + element.image + "_576.jpg"} />
                          <source media="(min-width: 320px)" srcSet={"chronik/" + element.image + "_384.jpg"} />
                          <img src={props.viewport < 576 ? "chronik/" + element.image + "_384.jpg" : "chronik/" + element.image + "_1280.jpg"} className="chronik-image" loading="lazy" title={element.imageTitle} alt={element.imageTitle} />
                        </picture>
                        : ""
                      }
                      <h5>{element.headline}</h5>
                      <p>{element.content}</p>
                      {/* Category buttons => click functionality fehlt noch */}
                      {element.categories.map(category =>
                        <Button className={"button netzwerk-button-round"} key={content.chronik.indexOf(element) + "_" + element.categories.indexOf(category)} onClick={() => handleClickCategory(category)}>
                          <span className={`icon-netzwerk icon-netzwerk-round icon-netzwerk-${category}`} />
                        </Button>
                      )}
                    </div>
                  )}

                  {(showStoriesQ4 === 4) ? <Button className="button button-jsdgreen" onClick={() => setShowStoriesQ4(20)}><span>Mehr anzeigen</span><IconArrow style={{ marginLeft: 8 }} /></Button> : ""}

                </div>
              </div>
            </div>
          </Col>

        </Row>
      </Container >
    </>
  );


}

export default Chronik;
