import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Footer = () => {

  return (
    <Container style={{ marginBottom: -14 }}>
      <Row>
        <Col xs={6} md={3} className="text-end gy-2">
          <a href="https://www.johannesstift-diakonie.de/datenschutz" target="_blank" rel="noreferrer" title="Datenschutzerklärung" className="text-night-blue">
            Datenschutz
          </a>
        </Col>
        <Col xs={6} md={3} className="text-start gy-2">
          <a href="https://www.johannesstift-diakonie.de/impressum-geschaeftsbericht-2022" target="_blank" rel="noreferrer" title="Impressum" className="text-night-blue">
            Impressum
          </a>
        </Col>
        <Col md={5} className="text-center text-md-end gy-2">
          <span>© 2023 Johannesstift Diakonie</span>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer;