import React, { useState, useEffect } from 'react';
import './NavBar.scss';
import { Link } from "react-scroll";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import throttle from 'lodash/throttle';

import { ReactComponent as NavClose } from '../../icons/icon-navigation-close.svg';
import { ReactComponent as NavOpen } from '../../icons/icon-navigation.svg';


const NavBar = (props) => {
  const [navbarShrink, setNavbarShrink] = useState((props.viewport < 1024) ? true : false);
  const [navbarIsExpanded, setNavbarIsExpanded] = useState(false);
  const [scrollProg, setScrollProg] = useState(window.scrollY / window.document.body.offsetHeight - window.innerHeight);



  // Scroll => shrink navbar (desktop)
  useEffect(() => {
    if (!(props.viewport < 1024)) {
      const windowHeight = window.document.body.offsetHeight;
      const viewportHeight = window.innerHeight;

      const handleScroll = () => {
        const scrollTop = window.scrollY;
        setScrollProg(scrollTop / (windowHeight - viewportHeight));
        if (scrollTop === 0) {
          setNavbarShrink(false);
        } else if (scrollTop > 137 - 64) {
          setNavbarShrink(true);
        }
      };

      const handleScrollThrottled = throttle(handleScroll, 250, { trailing: true, leading: true });

      window.addEventListener('scroll', handleScrollThrottled);

      return () => {
        window.removeEventListener('scroll', handleScrollThrottled);
      };
    }
  }, [props.viewport]);


  const toggleExpand = () => {

    const html = document.querySelector('html');

    // close mobile nav
    if (navbarIsExpanded) {
      setNavbarShrink(true);
      setNavbarIsExpanded(false)
      // enable scroll when closed 
      if (html) {
        html.style.overflowY = 'auto';
      }
    }
    // open mobile nav
    else {
      setNavbarShrink(false);
      setNavbarIsExpanded(true);
      // disable scroll when open
      if (html) {
        html.style.overflowY = 'hidden';
      }
    }
  }

  return (
    <div className={(props.viewport < 1024) ? "navbar-container bg-white px-0 py-0 w-100 position-absolute" : "navbar-container sticky-top bg-white px-0 py-0"} id="navbar-container">
      <Container className={(navbarIsExpanded && props.viewport < 1024) ? "py-0 navbar-container-expanded w-100" : "py-0"} >
        <Navbar collapseOnSelect expand="lg" className="py-0 w-100" expanded={navbarIsExpanded}>
          <Container className="px-0 py-0 flex-nowrap w-100" >
            <Navbar.Brand className="py-0" href="/" style={{ zIndex: "100" }} >
              <img
                src="/logo-jsd.svg"
                className={navbarShrink ? "d-inline-block align-top brand-shrinked" : "d-inline-block align-top brand"}
                alt="Logo Johannesstift Diakonie"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-icon" onClick={(props.viewport < 1024) ? toggleExpand : undefined}>{navbarIsExpanded ? <NavClose alt="Navigation schließen" /> : <NavOpen alt="Navigation öffnen" />}</Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav" className={(props.viewport < 1024) ? "nav-mobile w-100" : "justify-content-end"} style={(navbarIsExpanded || !(props.viewport < 1024)) ? { opacity: 1 } : { opacity: 0 }}>

              <Nav className="h-100">
                {(props.viewport < 1024) ? <h2 className="mobile-nav-header">Geschäftsjahr 2022</h2> : ""}
                <Nav.Link as={Link} className="nav-element text-night-blue nav-link d-flex h-100 align-items-center" spy to="vorstand" spyThrottle={500} onClick={(props.viewport < 1024) ? toggleExpand : undefined}>
                  Vorworte
                </Nav.Link>
                <Nav.Link as={Link} className="nav-element text-night-blue nav-link d-flex h-100 align-items-center" spy to="netzwerk" spyThrottle={500} onClick={(props.viewport < 1024) ? toggleExpand : undefined}>
                  Netzwerk
                </Nav.Link>
                <Nav.Link as={Link} className="nav-element text-night-blue nav-link d-flex h-100 align-items-center" spy to="chronik" spyThrottle={500} onClick={(props.viewport < 1024) ? toggleExpand : undefined}>
                  Chronik
                </Nav.Link>
                <Nav.Link as={Link} className="nav-element text-night-blue nav-link d-flex h-100 align-items-center" spy to="kennzahlen" spyThrottle={500} onClick={(props.viewport < 1024) ? toggleExpand : undefined}>
                  Kennzahlen
                </Nav.Link>
                <Nav.Link as={Link} className="nav-element text-night-blue nav-link d-flex h-100 align-items-center" spy to="karte-scrollspy" spyThrottle={500} onClick={(props.viewport < 1024) ? toggleExpand : undefined}>
                  Einrichtungen
                </Nav.Link>
                <Nav.Link as={Link} className="nav-element text-night-blue nav-link d-flex h-100 align-items-center" spy to="download" spyThrottle={500} onClick={(props.viewport < 1024) ? toggleExpand : undefined}>
                  Download
                </Nav.Link>
                {(props.viewport < 1024) ? <div className="d-flex h-100 align-items-center">
                  {/* <Nav.Link as={Link} className="nav-element-bottom" to="/" onClick={(props.viewport <1024) ? toggleExpand : undefined}>
                    Datenschutz
                  </Nav.Link> */}
                  <Nav.Link href="https://www.johannesstift-diakonie.de/datenschutz" target="_blank" rel="noreferrer" title="Datenschutzerklärung" className="nav-element-bottom">
                    Datenschutz
                  </Nav.Link>
                  <Nav.Link href="https://www.johannesstift-diakonie.de/impressum-geschaeftsbericht-2022" target="_blank" rel="noreferrer" title="Impressum" className="nav-element-bottom">
                    Impressum
                  </Nav.Link>
                </div> : ""}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar >
        {!(props.viewport < 1024) ? <ProgressBar now={scrollProg * 100} label={`${scrollProg}%`} variant="custom" visuallyHidden /> : ""}
      </Container >
    </div >
  );
}
export default NavBar;
