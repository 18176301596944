import React, { useState, useRef, useEffect } from 'react';

import ReactPlayer from 'react-player/file';

import './Vorstand.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as PlayIcon } from '../../icons/icon-play.svg';

const Vorstand = () => {
  const playerRef = useRef(null);
  const [videoNumber, setVideoNumber] = useState(1);
  const [videoUrl, setVideoUrl] = useState("videos/JSD_GB_2022_Vorwort_Vorstand.mp4");
  const [showModal, setShowModal] = useState(false);

  const swipeContainerRef = useRef(null);
  const [startX, setStartX] = useState(null);
  const [swipeDistance, setSwipeDistance] = useState(0);

  // reset to preview mode (video still) when swapping videos
  useEffect(
    () => {
      if (playerRef && playerRef?.current) {
        playerRef.current.showPreview();
      }
    }, [videoNumber]
  );

  // swap video
  const togglePager = () => {
    if (videoNumber === 1) {
      setVideoNumber(2);
      setVideoUrl(`videos/JSD_GB_2022_Vorwort_Aufsichtsrat.mp4`);
    }
    else {
      setVideoNumber(1);
      setVideoUrl(`videos/JSD_GB_2022_Vorwort_Vorstand.mp4`);
    }
  }

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setStartX(touch.clientX);
  };

  const handleTouchMove = (event) => {
    if (!startX) return;
    const touch = event.touches[0];
    const distance = touch.clientX - startX;
    setSwipeDistance(distance);
  };

  const handleTouchEnd = () => {
    if (swipeDistance < (-60)) {
      // Swiped left
      togglePager();
      console.log();
    } else if (swipeDistance > 60) {
      // Swiped right
      togglePager();
      console.log('Swiped right');
    }

    // Reset swipe distance and startX
    setSwipeDistance(0);
    setStartX(null);
  };

  return (
    <Container className="vorstand-container"
      ref={swipeContainerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      <Row>
        <h2 className="vorstand-h2">Wir ziehen Bilanz</h2>
      </Row>
      <Row>
        <Col xs={6}>
          <div className="pager-container">
            <div className="icon-arrow icon-left" onClick={togglePager}></div>
            <div className="pager">{videoNumber}/2</div>
            <div className="icon-arrow icon-right" onClick={togglePager}></div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12} lg={6}>
          <Row>
            <Col md={10}>
              <div className="poster-container">
                <img src={videoUrl.slice(0, -3) + "jpg"} className="video-poster" title="Video starten" alt="Video: Vorwort" onClick={handleShowModal} />
                <PlayIcon className="play-icon" onClick={handleShowModal} alt="Play button" width="66px" height="66px" />
              </div>
              <Modal dialogClassName="video-modal" size="xl" show={showModal} onHide={handleCloseModal} centered>
                <Modal.Body className="video-modal-body">
                  <div className="player-wrapper">
                    <ReactPlayer
                      className='react-player'
                      url={videoUrl}
                      ref={playerRef}
                      width="100%"
                      height="100%"
                      playing={true}
                      controls={true}
                      wrapper="div"
                    />
                  </div>

                </Modal.Body>
              </Modal>

            </Col>
          </Row>
        </Col>
        {(videoNumber === 1) ?
          <Col>
            <h4 className="vorstand-h4">Andreas Mörsberger</h4>
            <h5 className="vorstand-h5">Sprecher des Vorstandes</h5>
            <p className="text-lg-regular">
              „Das Jahr 2022 war zweifellos ein weiteres Krisenjahr. (…) Dennoch hat die Johannesstift Diakonie unter diesen schwierigen Bedingungen die gesteckten Ziele erreicht und sich auf beständigem Niveau weiterentwickelt.“            </p>

            <a
              className="mt-3 text-lg-regular text-night-blue" title="Vorwort herunterladen (PDF)"
              href="pdfs/JSDgAG_GB22_Vorworte_Moersberger.pdf" target="_blank">Vorwort des Vorstandes herunterladen (PDF)
            </a>
          </Col> :
          <Col>
            <h4 className="vorstand-h4">Prof. Dr. Bernd Schlüter</h4>
            <h5 className="vorstand-h5">Vorsitzender des Aufsichtsrates</h5>
            <p className="text-lg-regular">
              „Wir können sagen, dass wir wirtschaftlich solide sind, dass wir für unsere Qualität anerkannt sind, dass wir ein hohes Ansehen haben bei den Mitarbeitenden und bei den Bewohnern und Patienten.“
            </p>
            <a
              className="mt-3 text-lg-regular text-night-blue" title="Vorwort herunterladen (PDF)"
              href="pdfs/JSDgAG_GB22_Vorworte_Schlueter.pdf" target="_blank">Vorwort des Aufsichtsrates herunterladen (PDF)
            </a>
          </Col>}
      </Row>


    </Container>
  );
}


export default Vorstand;
